import React from 'react';
import { Helmet } from "react-helmet";
import { isServer } from 'shared';
import pjson from '../../../../package.json';
import { useLocation } from 'react-router-dom';
import os from 'os';

export default ({ metadata }) => {
    const location = useLocation()
    const mapMetadata = () => {
        

        let UrlCanonica = `${os.hostname()}${location.pathname}`.toLowerCase();

        if (metadata !== undefined && metadata !== null){
            if(metadata.UrlCanonica !== undefined && metadata.UrlCanonica !== null){
                UrlCanonica =`${metadata.UrlCanonica}`.toLowerCase();
            }
        }
    
        return (
            metadata ? 
                {
                    title: metadata.Title,
                    description: metadata.MetaDescription,
                    canonical: UrlCanonica,
                    image: metadata.UrlImagen,
                    url: !isServer() ? window.location.href : '',
                }
            : {});
    }

    const {title, description, canonical, image, url} = mapMetadata();
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="version" content={pjson.version} />
            {/* facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
            <meta property="og:description" content={description} />

            {/* twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:image" content={image} />
            <meta property="twitter:description" content={description} />
            <link rel="canonical" href={canonical} />
        </Helmet>
    );
}